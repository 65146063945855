import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  EtablissementValidationSchema,
  EtablissementValidationType,
  Localisation,
  Metier as MetierType,
  TypeEtablissement,
  emptyLocalisation,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";
import InformationDE from "./InformationDE/InformationDE";
import InformationsEtablissement from "./InformationsEtablissement/InformationsEtablissement";
import InformationsRefVMV from "./InformationsRefVMV/InformationsRefVMV";
import PossiblitesAccueils from "./PossiblitesAccueils/PossiblitesAccueils";

import { defaultMetitersList } from "../../../data/possibilitesAccueils.data";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";
import {
  executeAddressNotValideSideEffect,
  formatEtablissementObject,
} from "./utils";

import { Etablissement } from "../../../schemas-and-types/etablissement/etablissemen.schema";
import { usePagesToDisplayStore } from "../../../stores/pages-to-display/pages-to-display.store";

import FooterBackGroundImage from "../../../assets/png/error-page-decoration.png";
import { validerButtonIcon } from "../../../assets/svgs/icons/icons.svg";
import Button from "../../global/Button/Button";
import styles from "./VMVInscriptionEtablissement.module.scss";

function VMVInscriptionEtablissement() {
  //* react-hook-form
  const {
    register,
    handleSubmit,
    formState,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<EtablissementValidationType>({
    resolver: yupResolver(EtablissementValidationSchema),
  });
  const formErrors = formState.errors;
  const watchTypeEtablissement = watch("type") || "EHPAD";
  // * useState

  const [metiers, setMetiers] = useState<MetierType[]>(defaultMetitersList);
  const [localisationOption, setLocalisationOption] =
    useState<Localisation>(emptyLocalisation);

  // * react-router-dom
  const navigate = useNavigate();

  // * msal-react
  const { accounts } = useMsal();
  const oid = accounts[0].idTokenClaims?.oid as string;

  //* Zustand
  const { setEtablissement } = useEtablissementStore();
  const { setPagesToDisplay } = usePagesToDisplayStore();

  const onSubmit = (data: EtablissementValidationType) => {
    if (localisationOption.address === "") {
      executeAddressNotValideSideEffect(setError);
      return;
    }

    const newEtablissement = formatEtablissementObject(
      data,
      localisationOption && localisationOption,
      oid,
      metiers
    );
    setEtablissement(newEtablissement as Etablissement);
    setPagesToDisplay(["recapitulatif"]);
    navigate("/recapitulatif");
  };

  return (
    <article className={styles.inscriptionEtablissement}>
      <header className={styles.inscriptionEtablissement__header}>
        <h1>Inscription</h1>
        <section className={styles.inscriptionEtablissement__header__info}>
          <p>
            Avant de confirmer votre participation au Vis ma Vie {process.env.REACT_APP_DATE_YEAR}, nous
            avons besoins de quelques informations.
          </p>
          <p>
            Une fois votre participation réalisée, vos données seront
            supprimées.
          </p>
          <br />
        </section>
        <section className={styles.inscriptionEtablissement__header__obg}>
          <p>*Ces champs sont obligatoires.</p>
        </section>
      </header>
      <main className={styles.inscriptionEtablissement__main}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InformationsEtablissement
            register={register}
            formErrors={formErrors}
            setError={setError}
            clearErrors={clearErrors}
            localisationOption={localisationOption}
            setLocalisationOption={setLocalisationOption}
            setRegisterValue={setValue}
          />
          <InformationDE
            register={register}
            formErrors={formErrors}
            setRegisterValue={setValue}
          />
          <InformationsRefVMV
            register={register}
            formErrors={formErrors}
            setRegisterValue={setValue}
          />

          <PossiblitesAccueils
            metiers={metiers}
            setMetiers={setMetiers}
            typeEtablissement={watchTypeEtablissement as TypeEtablissement}
            onClickEnregister={() => navigate(0)}
          />
          <Button
            type="submit"
            text="Valider"
            rightIcon={validerButtonIcon}
            buttonBackGroundColor="linear-blue"
            textColor="white"
          />
          {/* <button type="submit">{`Valider ${validerButtonIcon}`}</button> */}
        </form>
      </main>
      <footer
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
    </article>
  );
}

export default VMVInscriptionEtablissement;
