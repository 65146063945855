import { format } from "date-fns";
import { fr } from "date-fns/locale";

import styles from "./MetierRecap.module.scss";
import { Disponibilite } from "../../../../../schemas-and-types/etablissement/etablissemen.schema";
import { minusIcon } from "../../../../../assets/svgs/icons/icons.svg";

type JourBoxProps = {
  disponibilite: Disponibilite;
  jourIndex: number;
};
export default function JourBoxRecap(props: JourBoxProps) {
  const { disponibilite } = props;
  return (disponibilite && disponibilite.nombre_professionnel_disponible!==0) ? (
    <article className={styles.metier__jourBox}>
      <section className={styles.metier__jourBox__date}>
        <label>
          {format(new Date(disponibilite.date), "EEEE d MMMM", { locale: fr }).replace(/^./, (str) => str.toUpperCase())}
        </label>
      </section>

      <section className={styles.metier__jourBox__info}>
        <label>Possibilité(s) d'accueil(s)</label>
        <p>{disponibilite.nombre_professionnel_disponible}</p>
      </section>
    </article>
  ) :   
  (
    <article className={styles.metier__jourBox}>
      <section className={styles.metier__jourBox__date}>
        <label>
          {format(new Date(disponibilite.date), "EEEE d MMMM", { locale: fr }).replace(/^./, (str) => str.toUpperCase())}
        </label>
      </section>

      <section className={styles.metier__jourBox__info}>
        <label>Possibilité(s) d'accueil(s)</label>
        <p>{minusIcon}</p>
      </section>
    </article>
  );
}
