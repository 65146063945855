import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import styles from "./TextAreaSection.module.scss";
import { useEffect } from "react";

type Props = {
  label: string;
  placeholder: string;
  textAreaId: string;
  name: string;
  required?: boolean;
  register: UseFormRegister<any>;
  formErrors: DeepMap<any, FieldError>;
};
export default function TextAreaSection(props: Props) {
  const {
    label,
    placeholder,
    textAreaId,
    name,
    register,
    formErrors,
  } = props;
  const errorName = formErrors?.[name];

  useEffect(() => {
    const textarea = document.getElementById(textAreaId) as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }, [textAreaId]);

  return (
    <section className={styles.textAreaSection}>
      <label
        className={styles.textAreaSection__titleLabel}
        htmlFor={textAreaId}
      >
        {label}
      </label>

      <textarea
        id={textAreaId}
        placeholder={placeholder}
        {...register(name)}
        has-error={errorName ? "yes" : "no"}
        className={styles.textAreaSection__textarea}
      />
      <label
        className={styles.textAreaSection__errorLabel}
        htmlFor={textAreaId}
      >
        {formErrors?.[name]?.message}
      </label>
    </section>
  );
}
