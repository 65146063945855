import {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useGetRegions } from "../../../../hooks/react-query/dropdowns/regions.queries";
import { useGetTypeEtablissement } from "../../../../hooks/react-query/dropdowns/type-etablissement.queries";
import {
  EtablissementValidationType,
  Localisation,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import DropdownSection from "../../../global/DropdownSection/DropdownSection";
import InputSection from "../../../global/InputSection/InputSection";
import MapsSearchSection from "../../../global/MapsSearchSection/MapsSearchSection";
import TextAreaSection from "../../../global/TextAreaSection/TextAreaSection";

import styles from "./InformationsEtablissement.module.scss";

type Props = {
  register: UseFormRegister<EtablissementValidationType>;
  formErrors: FieldErrors<EtablissementValidationType>;
  setError: UseFormSetError<EtablissementValidationType>;
  clearErrors: UseFormClearErrors<any>;
  setLocalisationOption: React.Dispatch<React.SetStateAction<Localisation>>;
  setRegisterValue: UseFormSetValue<any>; // TODO : Remove 'any' type
  localisationOption: Localisation;
};
function InformationsEtablissement(props: Props) {
  const {
    register,
    formErrors,
    setError,
    clearErrors,
    localisationOption,
    setLocalisationOption,
    setRegisterValue,
  } = props;

  // Fethc the dropdowns options
  const {
    data: typeEtablissementsOptions,
    isLoading: areTypeEtabOptionsLoading,
  } = useGetTypeEtablissement();

  const { data: regionsOptions, isLoading: areRegionOptionsLoading } =
    useGetRegions();
  return (
    <article
      className={styles.inscriptionEtablissement__main__infoEtablissement}
    >
      <h2 id="anchor-inscription">Informations établissement *</h2>
      <article
        className={
          styles.inscriptionEtablissement__main__infoEtablissement__container
        }
      >
        <section
          className={
            styles.inscriptionEtablissement__main__infoEtablissement__container__left
          }
        >
          <section>
            <DropdownSection
              options={typeEtablissementsOptions?.data?.list || []}
              label="Type d'établissement"
              inputId="inscriptionEtab_infoEtab_type"
              placeholder="Sélectionner un type d'établissement"
              name="type"
              register={register}
              formErrors={formErrors}
              areOptionsLoading={areTypeEtabOptionsLoading}
            />
          </section>
          <section>
            <InputSection
              label="Nom de l'établissement"
              inputId="inscriptionEtab_infoEtab_nom"
              inputType="text"
              placeholder="Nom de l'établissement"
              name="name"
              required
              register={register}
              formErrors={formErrors}

            />

            <MapsSearchSection
              label="Adresse"
              inputId="inscriptionEtab_infoEtab_adresse"
              placeholder="Adresse de l'établissement"
              name="address"
              register={register}
              formErrors={formErrors}
              setError={setError}
              clearErrors={clearErrors}
              localisationOption={localisationOption}
              setLocalisationOption={setLocalisationOption}
              setRegisterValue={setRegisterValue}

            />
          </section>
          <section>
            <TextAreaSection
              label="Accès établissement et transports"
              textAreaId="inscriptionEtab_infoEtab_access"
              placeholder="Préciser l'accessibilité à votre établissement (axe routier, gare, station de métro, tramway, code portail,…)"
              name="information_access"
              required
              register={register}
              formErrors={formErrors}
            />
          </section>
        </section>
        <section
          className={
            styles.inscriptionEtablissement__main__infoEtablissement__container__separationLine
          }
        ></section>
        <section
          className={
            styles.inscriptionEtablissement__main__infoEtablissement__container__right
          }
        >
          <DropdownSection
            options={regionsOptions?.data?.list || []}
            label="Région opérationnelle de rattachement de l'établissement"
            inputId="inscriptionEtab_region"
            placeholder="Sélectionner une région opérationnelle"
            name="region"
            register={register}
            formErrors={formErrors}
            areOptionsLoading={areRegionOptionsLoading}
          />

          <section>
            <InputSection
              label="NOM du ou de la DR"
              inputId="inscriptionEtab_nomDR"
              inputType="text"
              placeholder="Nom du ou de la DR"
              name="nom_dr"
              required
              register={register}
              formErrors={formErrors}

            />
            <InputSection
              label="Prénom du ou de la DR"
              inputId="inscriptionEtab_prenomDR"
              inputType="text"
              placeholder="Prénom du ou de la DR"
              name="prenom_dr"
              required
              register={register}
              formErrors={formErrors}

            />
          </section>
        </section>
      </article>
    </article>
  );
}

export default InformationsEtablissement;
