import axiosClient from "../../libs/axios";

async function getRegions() {
  return axiosClient.get(`/drop-downs/regions`);
}

const regionsServices = {
  getRegions,
};
export default regionsServices;
