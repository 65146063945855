import VMVInscriptionEtablissement from "../../components/etablissement/InscriptionEtablissement/VMVInscriptionEtablissement";
import VMVDescription from "../../components/etablissement/VMVDescription/VMVDescription";
import VMVInscriptionPlaceholder from "../../components/etablissement/VMVInscriptionPlaceholder/VMVInscriptionPlaceholder";
import VMVValeurs from "../../components/etablissement/VMVValeurs/VMVValeurs";
import InscriptionAnnuleeNotification from "../../components/global/InscriptionAnnuleeNotification/InscriptionAnnuleeNotification";
import { useNotificationStore } from "../../stores/notifications/notifications.store";
import styles from "./EtablissementInscriptionPage.module.scss";
function EtablissementInscriptionPage() {
  const {
    showCancelInscriptionNotification,
    setShowCancelInscriptionNotification,
  } = useNotificationStore();
  return (
    <article className={styles.etablissementInscriptionPage}>
      <InscriptionAnnuleeNotification
        isNotifVisible={showCancelInscriptionNotification}
        setIsNotVisible={setShowCancelInscriptionNotification}
      />
      <VMVInscriptionPlaceholder />
      <VMVDescription />
      <VMVValeurs />
      <VMVInscriptionEtablissement />
    </article>
  );
}

export default EtablissementInscriptionPage;
