import BackGroundImage from "../../../assets/png/building_v2.png";

import styles from "./VMVInscriptionPlaceholder.module.scss";
function VMVInscriptionPlaceholder() {
  return (
    <article className={styles.vmvPlaceholder}>
      <section
        className={styles.vmvPlaceholder__left}
        style={{ backgroundImage: `url(${BackGroundImage})` }}
      ></section>
      <section className={styles.vmvPlaceholder__right}>
        <h1>Vis ma vie</h1>
        <h2>Inscrire votre établissement</h2>
        <p>
          Vous voulez participer au Vis ma vie {process.env.REACT_APP_DATE_YEAR} ? Ouvrez les portes de votre
          établissement pour faire découvrir nos métiers pas comme les autres !
        </p>
        <a type="button" href="#anchor-inscription" >Je m'inscris</a>
      </section>
    </article>
  );
}

export default VMVInscriptionPlaceholder;
