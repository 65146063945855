import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { defaultMetitersList } from "../../data/possibilitesAccueils.data";
import {
  Etablissement,
  Metier,
} from "../../schemas-and-types/etablissement/etablissemen.schema";

export const initialMetiersList: Metier[] = defaultMetitersList;

interface EtablissementState {
  etablissement: Etablissement | any;
  setEtablissement: (etablissement: Etablissement | any) => void;
}

export const useEtablissementStore = create<EtablissementState>()(
  devtools(
    persist(
      (set) => ({
        etablissement: "any", // initial value
        setEtablissement: (newEtablissementState) =>
          set((state) => ({ etablissement: newEtablissementState })),
      }),
      {
        name: "etablissement-state-storage",
      }
    )
  )
);
