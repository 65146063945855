import { Disponibilite } from "../../../../../schemas-and-types/etablissement/etablissemen.schema";
import JourBoxRecap from "./JourBoxRecap";
import styles from "./MetierRecap.module.scss";


const firstWeek = process.env.REACT_APP_DATE_FIRST_WEEK;
const secondWeek = process.env.REACT_APP_DATE_SECOND_WEEK;
const thirdWeek = process.env.REACT_APP_DATE_THIRD_WEEK;
const fourthWeek = process.env.REACT_APP_DATE_FOURTH_WEEK;

type Props = {
  title: string;
  job_title: string;
  premiere_semaine: Disponibilite[] | null;
  deuxieme_semaine: Disponibilite[] | null;
  troisieme_semaine: Disponibilite[] | null;
  quatrieme_semaine: Disponibilite[] | null;
};
export default function MetierRecap(props: Props) {
  const { title, job_title, premiere_semaine, deuxieme_semaine, troisieme_semaine, quatrieme_semaine } = props;

  return (
    <article className={styles.metierRecap}>
      <section>
        <h1>{title}</h1>
        <h2>{job_title}</h2>
      </section>


      {(premiere_semaine && deuxieme_semaine) === null ? (
        <section className={styles.metierRecap__disponibiliteCheckbox}>
          <input
            type="checkbox"
            checked
            readOnly
            id={`${title}AvailableCheckbox_recap`}
          />
          <label htmlFor={`${title}AvailableCheckbox_recap`}>
            Pas de disponibilités pour ce secteur d'activités
          </label>
        </section>
      ) : (
        <section
          className={styles.metier__semaines}

        >
          <article className={styles.metier__premiereSemaine}>
            <h3>Première semaine : {firstWeek}</h3>
            <section className={styles.metier__semaine__list}>
              {premiere_semaine &&
                premiere_semaine.map(
                  (disponibilite: Disponibilite, index: number) => {
                    return (
                      <JourBoxRecap
                        key={`jourBox_index_recap_${index}`}
                        disponibilite={disponibilite}
                        jourIndex={index}
                      />
                    );
                  }
                )}
            </section>
          </article>
          <article className={styles.metier__deuxiemeSemaine}>
            <h3>Deuxième semaine : {secondWeek}</h3>
            <section className={styles.metier__semaine__list}>
              {deuxieme_semaine &&
                deuxieme_semaine.map(
                  (disponibilite: Disponibilite, index: number) => {
                    return (
                      <JourBoxRecap
                        key={`jourBox_index_recap_${index}`}
                        disponibilite={disponibilite}
                        jourIndex={index}
                      />
                    );
                  }
                )}
            </section>
          </article>
          <article className={styles.metier__troisiemeSemaine}>
            <h3>Troisieme semaine : {thirdWeek}</h3>
            <section className={styles.metier__semaine__list}>
              {troisieme_semaine &&
                troisieme_semaine.map(
                  (disponibilite: Disponibilite, index: number) => {
                    return (
                      <JourBoxRecap
                        key={`jourBox_index_recap_${index}`}
                        disponibilite={disponibilite}
                        jourIndex={index}
                      />
                    );
                  }
                )}
            </section>
          </article>
          <article className={styles.metier__deuxiemeSemaine}>
            <h3>Quatrième semaine : {fourthWeek}</h3>
            <section className={styles.metier__semaine__list}>
              {quatrieme_semaine &&
                quatrieme_semaine.map(
                  (disponibilite: Disponibilite, index: number) => {
                    return (
                      <JourBoxRecap
                        key={`jourBox_index_recap_${index}`}
                        disponibilite={disponibilite}
                        jourIndex={index}
                      />
                    );
                  }
                )}
            </section>
          </article>
        </section>
      )}
    </article>
  );
}
