import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type PagesToDisplay = "recapitulatif" | "demande-envoyee";
interface PageToDisplayState {
  isModificationFlow: boolean;
  setIsModificationFlow: (isModificationFlow: boolean) => void;

  pagesToDisplay: PagesToDisplay[];
  setPagesToDisplay: (pagesToDisplay: PagesToDisplay[]) => void;
}

export const usePagesToDisplayStore = create<PageToDisplayState>()(
  devtools(
    persist(
      (set) => ({
        isModificationFlow: false,
        pagesToDisplay: [],
        setIsModificationFlow: (isModificationFlow: boolean) =>
          set((state) => ({ isModificationFlow: isModificationFlow })),
        setPagesToDisplay: (newPagesToDisplay: PagesToDisplay[]) =>
          set((state) => ({ pagesToDisplay: newPagesToDisplay })),
      }),

      {
        name: "pages-to-display-state-storage",
      }
    )
  )
);
