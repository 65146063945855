import { useNavigate } from "react-router-dom";

import { whiteCheckIcon } from "../../../../assets/svgs/icons/icons.svg";
import {
  disponiblitesDeuxiemeSemaine,
  disponiblitesPremiereSemaine,
  disponiblitesQuatriemeSemaine,
  disponiblitesTroisiemeSemaine,
} from "../../../../data/possibilitesAccueils.data";
import {
  Metier as MetierType,
  TypeEtablissement,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import Button from "../../../global/Button/Button";
import Metier from "../Metier/Metier";

import { useEffect } from "react";
import styles from "./PossiblitesAccueils.module.scss";

type Props = {
  setIsPossibiliteAccueilModification?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  metiers: MetierType[];
  setMetiers: React.Dispatch<React.SetStateAction<MetierType[]>>;
  typeEtablissement: TypeEtablissement;
  onClickEnregister: () => void;
};
export default function PossiblitesAccueils(props: Props) {
  // TODO : Fetch 'premiereSemaine' and 'deuxiemeSemaine' date arrays from the backend

  const {
    setIsPossibiliteAccueilModification,
    metiers, // to actual metiers which are stored in the etablissement
    setMetiers,
    typeEtablissement,
    onClickEnregister,
  } = props;

  const navigate = useNavigate();
  function onClickAnnulerModification() {
    navigate(0);
    setIsPossibiliteAccueilModification &&
      setIsPossibiliteAccueilModification(false);
  }

  useEffect(() => {
    if (
      typeEtablissement === "Clinique - Santé Mentale" ||
      typeEtablissement === "Clinique - SSR"
    ) {
      setMetiers((current) =>
        current.map((metier) => {
          if (metier.title === "Animation") {
            metier.premiere_semaine = disponiblitesPremiereSemaine;
            metier.deuxieme_semaine = disponiblitesDeuxiemeSemaine;
            metier.troisieme_semaine = disponiblitesTroisiemeSemaine;
            metier.quatrieme_semaine = disponiblitesQuatriemeSemaine;
            return {
              title: "Animation",
              job_title: "Animateur(trice)",
              premiere_semaine: disponiblitesPremiereSemaine,
              deuxieme_semaine: disponiblitesDeuxiemeSemaine,
              troisieme_semaine: disponiblitesTroisiemeSemaine,
              quatrieme_semaine: disponiblitesQuatriemeSemaine,
              isDisponible: true,
            };
          } else return metier;
        })
      );

      setMetiers((current) =>
        current.filter((metier) => metier.title !== "Animation")
      );
      return;
    } else if (typeEtablissement === "EHPAD") {
      if (!metiers.some((metier) => metier.title === "Animation")) {
        setMetiers((current) => [
          ...current,
          {
            title: "Animation",
            job_title: "Animateur(trice)",
            premiere_semaine: disponiblitesPremiereSemaine,
            deuxieme_semaine: disponiblitesDeuxiemeSemaine,
            troisieme_semaine: disponiblitesTroisiemeSemaine,
            quatrieme_semaine: disponiblitesQuatriemeSemaine,
            isDisponible: true,
          },
        ]);
      }
    }
  }, [typeEtablissement]);
  return (
    <article className={styles.possibilitesAccueils}>
      <header className={styles.possibilitesAccueils__header}>
        <h1>Possibilité(s) d'accueil(s) *</h1>
      </header>
      <main className={styles.possibilitesAccueils__main}>
        {metiers &&
          metiers.map((metierItem: MetierType, index: number) => {
            return (
              <Metier
                key={`metierItemPossibiliteAccueil_${index}_${metierItem.title}`}
                title={metierItem.title}
                job_title={metierItem.job_title}
                metierIndex={index}
                metiers={metiers}
                setMetiers={setMetiers}
              />
            );
          })}
      </main>

      {setIsPossibiliteAccueilModification && (
        <footer className={styles.possibilitesAccueils__footer}>
          <Button
            buttonBackGroundColor="white"
            text="Annuler les modifications"
            textColor="dark"
            onClick={onClickAnnulerModification}
          />
          <Button
            buttonBackGroundColor="blue"
            type="submit"
            text="Enregistrer les modifications"
            textColor="white"
            icon={whiteCheckIcon}
            onClick={onClickEnregister}
          />
        </footer>
      )}
    </article>
  );
}
