import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import styles from "./DropdownSection.module.scss";

export type DropdownTypeInBackend = {
  _id: string;
  label: string;
};
type Props = {
  options: string[] | DropdownTypeInBackend[];
  label: string;
  placeholder: string;
  inputId: string;
  name: string;
  register: UseFormRegister<any>; // !TODO : Remove 'any' type
  formErrors: DeepMap<any, FieldError>;
  areOptionsLoading?: boolean;
};

export default function DropdownSection(props: Props) {
  const {
    options,
    label,
    placeholder,
    inputId,
    name,
    register,
    formErrors,
    areOptionsLoading,
  } = props;

  const errorName = formErrors?.[name];
  return (
    <section className={styles.dropdownSection}>
      <label className={styles.dropdownSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <select
        has-error={errorName ? "yes" : "no"}
        {...register(name)}
        id={inputId}
        defaultValue={placeholder || "-"}
        // onChange={(e) => {
        //   setValue && setValue(e.target.value);
        //   setRegisterValue(name, e.target.value);
        // }}
      >
        {!areOptionsLoading ? (
          <>
            <option value={placeholder || "-"} disabled hidden>
              {placeholder || "-"}
            </option>

            {options.map((option, index) => {
              if (typeof option === "string")
                return (
                  <option key={`dropdownSection_${index}`} value={option}>
                    {option}
                  </option>
                );
              return (
                // Here the type of option is DropdownTypeInBackend
                <option key={`dropdownSection_${index}`} value={option?.label}>
                  {option?.label}
                </option>
              );
            })}
          </>
        ) : (
          <option value={""}>Chargement...</option>
        )}
      </select>

      <label className={styles.dropdownSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message}
      </label>
    </section>
  );
}
