import {
  Disponibilite,
  Metier,
} from "../schemas-and-types/etablissement/etablissemen.schema";

export const premiereSemaine = [
  // Première semaine
  new Date("2024-06-03"),
  new Date("2024-06-04"),
  new Date("2024-06-05"),
  new Date("2024-06-06"),
  new Date("2024-06-07"),
];

export const deuxiemeSemaine = [
  // Deuxième semaine
  new Date("2024-06-10"),
  new Date("2024-06-11"),
  new Date("2024-06-12"),
  new Date("2024-06-13"),
  new Date("2024-06-14"),
];

export const troisiemeSemaine = [
  // Troisième semaine
  new Date("2024-06-17"),
  new Date("2024-06-18"),
  new Date("2024-06-19"),
  new Date("2024-06-20"),
  new Date("2024-06-21"),
];

export const quatriemeSemaine = [
  // Quatrième semaine
  new Date("2024-06-24"),
  new Date("2024-06-25"),
  new Date("2024-06-26"),
  new Date("2024-06-27"),
  new Date("2024-06-28"),
];

export const disponiblitesPremiereSemaine: Disponibilite[] = [
  ...premiereSemaine.map((jour: Date) => {
    return { date: jour, nombre_professionnel_disponible: 0 };
  }),
];
export const disponiblitesDeuxiemeSemaine: Disponibilite[] = [
  ...deuxiemeSemaine.map((jour: Date) => {
    return { date: jour, nombre_professionnel_disponible: 0 };
  }),
];
export const disponiblitesTroisiemeSemaine: Disponibilite[] = [
  ...troisiemeSemaine.map((jour: Date) => {
    return { date: jour, nombre_professionnel_disponible: 0 };
  }),
];
export const disponiblitesQuatriemeSemaine: Disponibilite[] = [
  ...quatriemeSemaine.map((jour: Date) => {
    return { date: jour, nombre_professionnel_disponible: 0 };
  }),
];

export const defaultMetitersList: Metier[] = [
  {
    title: "Restauration",
    job_title: "Cuisinier(ière)",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },
  {
    title: "Maintenance",
    job_title: "Responsable technique ou Technicien(ne)",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },

  {
    title: "Accueil et administration",
    job_title: "Agent d'accueil",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },
  {
    title: "Hôtellerie - Agent de service hôtelier (ASH)",
    job_title: "Agent de service hôtelier (ASH)",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },
  {
    title: "Hôtellerie - Serveur(euse)",
    job_title: "Serveur(euse)",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },
  {
    title: "Animation",
    job_title: "Animateur(trice)",
    premiere_semaine: disponiblitesPremiereSemaine,
    deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    troisieme_semaine: disponiblitesTroisiemeSemaine,
    quatrieme_semaine: disponiblitesQuatriemeSemaine,
    isDisponible: true,
  },
];
