import {
  Disponibilite,
  EtablissementValidationType,
  IEtablissement,
  Localisation,
  Metier,
  TypeEtablissement,
} from "../../../schemas-and-types/etablissement/etablissemen.schema";

export function formatEtablissementToUpdateUI(
  data: EtablissementValidationType,
  localisation: Localisation,
  oid: string,
  metiers: Metier[]
): IEtablissement {
  return {
    userOID: oid,
    name: data.name,
    type: data.type,
    // addresse: data.addresse,
    information_access: data.information_access,

    information_regional: {
      region: data.region,
      nom_dr: data.nom_dr,
      prenom_dr: data.prenom_dr,
    },
    information_DE: {
      nom_de: data.nom_de,
      prenom_de: data.prenom_de,
      email_de: data.email_de,
      phone_de: data.phone_de,
    },
    information_referent: {
      nom_referent: data.nom_referent,
      prenom_referent: data.prenom_referent,
      email_referent: data.email_referent,
      phone_referent: data.phone_referent,
    },
    localisation: localisation,
    metiers: metiers.map((metier: Metier) => {
      // in order not avoid sending null instead of a indisponible metier
      if (metier.isDisponible === false)
        return {
          title: metier.title,
          job_title: metier.job_title,
          premiere_semaine: null,
          deuxieme_semaine: null,
          troisieme_semaine: null,
          quatrieme_semaine: null,
        };
      return {
        title: metier.title,
        job_title: metier.job_title,
        premiere_semaine: metier.premiere_semaine,
        deuxieme_semaine: metier.deuxieme_semaine,
        troisieme_semaine: metier.troisieme_semaine,
        quatrieme_semaine: metier.quatrieme_semaine,
      };
    }),
  };
}

export function formatEtablissementToUpdate(
  etablissement: IEtablissement,
  localisation: Localisation,
  oid: string,
  metiers: Metier[]
): IEtablissement {
  return {
    userOID: oid,
    name: etablissement.name,
    type: etablissement.type,
    // addresse: etablissement.addresse,
    information_access: etablissement.information_access,

    information_regional: {
      region: etablissement.information_regional.region,
      nom_dr: etablissement.information_regional.nom_dr,
      prenom_dr: etablissement.information_regional.prenom_dr,
    },
    information_DE: {
      nom_de: etablissement.information_DE.nom_de,
      prenom_de: etablissement.information_DE.prenom_de,
      email_de: etablissement.information_DE.email_de,
      phone_de: etablissement.information_DE.phone_de,
    },
    information_referent: {
      nom_referent: etablissement?.information_referent?.nom_referent,
      prenom_referent: etablissement?.information_referent?.prenom_referent,
      email_referent: etablissement?.information_referent?.email_referent,
      phone_referent: etablissement?.information_referent?.phone_referent,
    },
    localisation: localisation,
    metiers: metiers.map((metier: Metier) => {
      // in order not avoid sending null instead of a indisponible metier
      if (metier.isDisponible === false)
        return {
          title: metier.title,
          job_title: metier.job_title,
          premiere_semaine: null,
          deuxieme_semaine: null,
          troisieme_semaine: null,
          quatrieme_semaine: null,
        };
      return {
        title: metier.title,
        job_title: metier.job_title,
        premiere_semaine: metier.premiere_semaine,
        deuxieme_semaine: metier.deuxieme_semaine,
        troisieme_semaine: metier.troisieme_semaine,
        quatrieme_semaine: metier.quatrieme_semaine,
      };
    }),
  };
}

export function isTypeEtablissementChangeToEHPAD(
  oldValue: TypeEtablissement,
  newValue: TypeEtablissement
) {
  return oldValue !== "EHPAD" && newValue === "EHPAD";
}

export function removeAnimationBlock(
  setMetiers: (value: React.SetStateAction<Metier[]>) => void,
  disponiblitesPremiereSemaine: Disponibilite[],
  disponiblitesDeuxiemeSemaine: Disponibilite[],
  disponiblitesTroisiemeSemaine: Disponibilite[],
  disponiblitesQuatriemeSemaine: Disponibilite[],
) {
  setMetiers((current) => [
    ...current.map((metier) => {
      if (metier.title === "Animation")
        return {
          title: "Animation",
          job_title: "Animateur(trice)",
          premiere_semaine: disponiblitesPremiereSemaine,
          deuxieme_semaine: disponiblitesDeuxiemeSemaine,
          troisieme_semaine: disponiblitesTroisiemeSemaine,
          quatrieme_semaine: disponiblitesQuatriemeSemaine,
          isDisponible: true,
        };
      else return metier;
    }),
  ]);
}

export function addAnimationBlock(
  metiers: Metier[],
  setMetiers: (value: React.SetStateAction<Metier[]>) => void,
  disponiblitesPremiereSemaine: Disponibilite[],
  disponiblitesDeuxiemeSemaine: Disponibilite[],
  disponiblitesTroisiemeSemaine: Disponibilite[],
  disponiblitesQuatriemeSemaine: Disponibilite[]
) {
  const newMetierList = [
    ...metiers,
    {
      title: "Animation",
      job_title: "Animateur(trice)",
      premiere_semaine: disponiblitesPremiereSemaine,
      deuxieme_semaine: disponiblitesDeuxiemeSemaine,
      troisieme_semaine: disponiblitesTroisiemeSemaine,
      quatrieme_semaine: disponiblitesQuatriemeSemaine,
      isDisponible: true,
    },
  ];
  if (!metiers.some((metier) => metier.title === "Animation"))
    // setMetiers((current) => [
    //   ...current,
    //   {
    //     title: "Animation",
    //     job_title: "Animateur(trice)",
    //     premiere_semaine: disponiblitesPremiereSemaine,
    //     deuxieme_semaine: disponiblitesDeuxiemeSemaine,
    //     troisime_semaine: disponiblitesTroisiemeSemaine,
    //     quatrieme_semaine: disponiblitesQuatriemeSemaine,
    //     isDisponible: true,
    //   },
    // ]);
    setMetiers(newMetierList);
}
