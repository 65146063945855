import { useState } from "react";

import { Metier as MetierType } from "../../../../schemas-and-types/etablissement/etablissemen.schema";

import {
  deuxiemeSemaine,
  disponiblitesDeuxiemeSemaine,
  disponiblitesPremiereSemaine,
  disponiblitesQuatriemeSemaine,
  disponiblitesTroisiemeSemaine,
  premiereSemaine,
  quatriemeSemaine,
  troisiemeSemaine
} from "../../../../data/possibilitesAccueils.data";
import JourBox from "./JourBox";

import styles from "./Metier.module.scss";

const firstWeek = process.env.REACT_APP_DATE_FIRST_WEEK;
const secondWeek = process.env.REACT_APP_DATE_SECOND_WEEK;
const thirdWeek = process.env.REACT_APP_DATE_THIRD_WEEK;
const fourthWeek = process.env.REACT_APP_DATE_FOURTH_WEEK;




type Props = {
  title: string;
  job_title: string;
  metierIndex: number;
  metiers: MetierType[];
  setMetiers: React.Dispatch<React.SetStateAction<MetierType[]>>;
};



export default function Metier(props: Props) {

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index: number) => {
    setToggleState(index);
  };



  const { title, job_title, metierIndex, metiers, setMetiers } = props;

  const isNotDisponibleInitialValue =
    metiers[metierIndex]?.premiere_semaine === null &&
    metiers[metierIndex]?.deuxieme_semaine === null &&
    metiers[metierIndex]?.troisieme_semaine === null &&
    metiers[metierIndex]?.quatrieme_semaine === null;
  const [isNotDisponible, setIsnotDisponible] = useState<boolean>(
    isNotDisponibleInitialValue
  );

  function onCheckDisponiblite() {
    if (!isNotDisponible) {
      setMetiers((current: MetierType[]) => [
        ...current.map((metier: MetierType) => {
          if (metier.job_title === job_title)
            return {
              title: metier.title,
              job_title: metier.job_title,
              premiere_semaine: metier.premiere_semaine,
              deuxieme_semaine: metier.deuxieme_semaine,
              troisieme_semaine: metier.troisieme_semaine,
              quatrieme_semaine: metier.quatrieme_semaine,
              isDisponible: false,
            };
          else return metier;
        }),
      ]);
    }

    if (isNotDisponible) {
      setMetiers((current: MetierType[]) => [
        ...current.map((metier: MetierType) => {
          if (metier.job_title === job_title)
            return {
              title: metier.title,
              job_title: metier.job_title,
              premiere_semaine: disponiblitesPremiereSemaine,
              deuxieme_semaine: disponiblitesDeuxiemeSemaine,
              troisieme_semaine: disponiblitesTroisiemeSemaine,
              quatrieme_semaine: disponiblitesQuatriemeSemaine,
              isDisponible: true,
            };
          else return metier;
        }),
      ]);
    }

    setIsnotDisponible((current: boolean) => !current);
  }
  return (
    <article className={styles.metier}>
      <section className={styles.metier__metier}>
        <h1>{title}</h1>
        <h2>{job_title}</h2>
      </section>

      <section className={styles.metier__disponibiliteCheckbox}>
        <input
          onChange={onCheckDisponiblite}
          type="checkbox"
          checked={isNotDisponible}
          name={`is${title}AvailableCheckbox`}
          id={`${title}AvailableCheckbox`}
        />
        <label htmlFor={`${title}AvailableCheckbox`}>
          Pas de disponibilités pour ce secteur d'activités
        </label>
      </section>
      {!isNotDisponible && (
        <section className={styles.container__tabs}>
          <section className={styles.bloc__tabs}>
            <article className={toggleState === 1 ? `${styles.metier__premiereSemaine} ${styles.active__tab}` : styles.tabs} onClick={() => toggleTab(1)}>
              <h3>Première Semaine</h3>
              <p>{firstWeek}</p>
            </article>
            <article className={toggleState === 2 ? `${styles.metier__deuxiemeSemaine} ${styles.active__tab}` : styles.tabs} onClick={() => toggleTab(2)} >
              <h3>Deuxième Semaine</h3>
              <p>{secondWeek}</p>
            </article>
            <article className={toggleState === 3 ? `${styles.metier__troisiemeSemaine} ${styles.active__tab}` : styles.tabs} onClick={() => toggleTab(3)} >
              <h3>Troisième Semaine</h3>
              <p>{thirdWeek}</p>
            </article>
            <article className={toggleState === 4 ? `${styles.metier__premiereSemaine} ${styles.active__tab}` : styles.tabs} onClick={() => toggleTab(4)}>
              <h3>Quatrième Semaine</h3>
              <p>{fourthWeek}</p>
            </article>
          </section>

          <section className={styles.metier__semaines}>
            <article style={{ display: toggleState !== 1 ? 'none' : 'block' }} className={styles.metier__premiereSemaine}>
              <h3>Première semaine : {firstWeek}</h3>
              <section className={styles.metier__semaine__list}>
                {premiereSemaine.map((jour: Date, jourIndex: number) => {
                  const initialCount =
                    metiers[metierIndex]?.premiere_semaine[jourIndex]
                      ?.nombre_professionnel_disponible;
                  return (
                    <JourBox
                      key={`jourBox_index_${jourIndex}`}
                      initialCount={initialCount}
                      jour={jour}
                      job_title={job_title}
                      metierIndex={metierIndex}
                      metiers={metiers}
                      setMetiers={setMetiers}
                    />
                  );
                })}
              </section>
            </article>
            <article style={{ display: toggleState !== 2 ? 'none' : 'block' }} className={styles.metier__premiereSemaine}>
              <h3>Deuxième semaine : {secondWeek}</h3>
              <section className={styles.metier__semaine__list}>
                {deuxiemeSemaine.map((jour: Date, jourIndex: number) => {
                  const initialCount =
                    metiers[metierIndex]?.deuxieme_semaine[jourIndex]
                      ?.nombre_professionnel_disponible;
                  return (
                    <JourBox
                      key={`jourBox_index_${jourIndex}`}
                      initialCount={initialCount}
                      jour={jour}
                      job_title={job_title}
                      metiers={metiers}
                      metierIndex={metierIndex}
                      setMetiers={setMetiers}
                    />
                  );
                })}
              </section>
            </article>
            <article style={{ display: toggleState !== 3 ? 'none' : 'block' }} className={styles.metier__premiereSemaine}>
              <h3>Troisieme semaine : {thirdWeek}</h3>
              <section className={styles.metier__semaine__list}>
                {troisiemeSemaine.map((jour: Date, jourIndex: number) => {
                  const initialCount =
                    metiers[metierIndex]?.troisieme_semaine[jourIndex]
                      ?.nombre_professionnel_disponible;
                  return (
                    <JourBox
                      key={`jourBox_index_${jourIndex}`}
                      initialCount={initialCount}
                      jour={jour}
                      job_title={job_title}
                      metiers={metiers}
                      metierIndex={metierIndex}
                      setMetiers={setMetiers}
                    />
                  );
                })}
              </section>
            </article>
            <article style={{ display: toggleState !== 4 ? 'none' : 'block' }} className={styles.metier__premiereSemaine}>
              <h3>Quatrieme semaine : {fourthWeek}</h3>
              <section className={styles.metier__semaine__list}>
                {quatriemeSemaine.map((jour: Date, jourIndex: number) => {
                  const initialCount =
                    metiers[metierIndex]?.quatrieme_semaine[jourIndex]
                      ?.nombre_professionnel_disponible;
                  return (
                    <JourBox
                      key={`jourBox_index_${jourIndex}`}
                      initialCount={initialCount}
                      jour={jour}
                      job_title={job_title}
                      metiers={metiers}
                      metierIndex={metierIndex}
                      setMetiers={setMetiers}
                    />
                  );
                })}
              </section>
            </article>
          </section>
        </section>
      )
      }
    </article >
  );
}
