import styles from "./InformationsRefVMV.module.scss";
import InputSection from "../../../global/InputSection/InputSection";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { EtablissementValidationType } from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import PhoneSection from "../../../global/PhoneSection/PhoneSection";

type Props = {
  register: UseFormRegister<EtablissementValidationType>;
  formErrors: FieldErrors<EtablissementValidationType>;
  setRegisterValue: UseFormSetValue<any>; // TODO : Remove 'any' type

};
function InformationsRefVMV(props: Props) {
  const { register, formErrors,setRegisterValue } = props;

  return (
    <article className={styles.inscriptionEtablissement__main__infoRef}>
      <h2>Informations référent(e) Vis ma vie</h2>
      <p>
        Le ou la référent(e) Vis ma vie est une personne pouvant être contactée
        en cas de besoin par le ou la collaborateur(trice) réalisant sa journée
        Vis ma vie dans votre établissement.
      </p>
      <article
        className={styles.inscriptionEtablissement__main__infoRef__inputs}
      >
        <section>
          <InputSection
            label="NOM du ou de la référent(e)"
            inputId="inscriptionEtab_infoRef_nomRef"
            inputType="text"
            placeholder="Nom du ou de la référent(e)"
            name="nom_referent"
            required
            register={register}
            formErrors={formErrors}
            
          />

          <InputSection
            label="Prénom du ou de la référent(e)"
            inputId="inscriptionEtab_infoRef_prenomRef"
            inputType="text"
            placeholder="Prénom du ou de la référent(e)"
            name="prenom_referent"
            required
            register={register}
            formErrors={formErrors}
            
          />
        </section>

        <section>
          <InputSection
            label="Email"
            inputId="inscriptionEtab_infoRef_emailRef"
            inputType="text"
            placeholder="prenom.nom@clariane.com"
            name="email_referent"
            required
            register={register}
            formErrors={formErrors}
            
          />

          <PhoneSection
            label="Téléphone"
            inputId="inscriptionEtab_infoRef_phoneRef"
            placeholder="0 00 00 00 00"
            name="phone_referent"
            required
            register={register}
            formErrors={formErrors}
            setRegisterValue={setRegisterValue}
            

          />
        </section>
      </article>
    </article>
  );
}

export default InformationsRefVMV;
