import { crayonIcon } from "../../../../assets/svgs/icons/icons.svg";
import Button from "../../../global/Button/Button";
import InformationSection from "../../../global/InformationSection/InformationSection";
import { IEtablissement } from '../../../../schemas-and-types/etablissement/etablissemen.schema';

import styles from "./InformationEtabRecap.module.scss";
import { formatPhoneNumber } from "./utils";

type Props = {
  canUpdate: boolean;
  setIsInfoEtabModification?: React.Dispatch<React.SetStateAction<boolean>>
  etablissement : IEtablissement
};
export default function InformationEtabRecap(props: Props) {
  const { canUpdate, etablissement,setIsInfoEtabModification } = props;

  function onClickModifier (){
    setIsInfoEtabModification && setIsInfoEtabModification(true)
  }

  return (
    <article className={styles.informationEtab}>
      <header>
        <h2>Informations établissement *</h2>
        {canUpdate && (
          <Button
            buttonBackGroundColor="white"
            textColor="dark"
            type="button"
            icon={crayonIcon}
            text="Modifier"
            onClick={onClickModifier}
          />
        )}
      </header>
      <main>
        <article className={styles.informationEtab__top}>
          <section className={styles.informationEtab__top__left}>
            <section>
              <InformationSection label="Type d'établissement"   text={etablissement?.type ?? '-'} />
            </section>
            <section>
              <InformationSection
                label="Nom de l'établissement"
                text={etablissement?.name ?? '-'}
              />
              <InformationSection
                label="Adresse"
                text={etablissement?.localisation?.address ?? '-'}
              />
            </section>
            <section>
              <InformationSection
              
                label="Accès établissement et transports"
                text={etablissement?.information_access ?? '-'}
                maxSectionWidth="684px"
              />
            </section>
          </section>
          <section
            className={styles.informationEtab__top__separationLine}
          ></section>
          <section className={styles.informationEtab__top__right}>
            <section>
              <InformationSection
                label="Région opérationnelle de rattachement de l'établissement"
                text={etablissement?.information_regional?.region ?? '-'}
              />
            </section>
            <section>
              <InformationSection
                label="NOM du ou de la DR"
                text={etablissement?.information_regional?.nom_dr ?? '-'}
              />
              <InformationSection
                label="Prénom du ou de la DR"
                text={etablissement?.information_regional?.prenom_dr ?? '-'}
              />
            </section>
          </section>
        </article>

        <section className={styles.informationEtab__bottom}>
          <article className={styles.informationEtab__bottom__DE}>
            <h2>Informations Directeur(trice) d’établissement *</h2>
            <main>
              <section>
                <InformationSection
                  label="NOM du ou de la DE"
                  text={etablissement?.information_DE?.nom_de ?? '-'}
                />
                <InformationSection label="Email" text={etablissement?.information_DE?.email_de ?? '-'} />
              </section>
              <section>
                <InformationSection
                  label="Prénom du ou de la DE"
                  text={etablissement?.information_DE?.prenom_de ?? '-'}
                />
                <InformationSection label="Téléphone" text={formatPhoneNumber(etablissement?.information_DE?.phone_de) ?? '-'} />
              </section>
            </main>
          </article>
          <article className={styles.informationEtab__bottom__REF}>
            <h2>Informations Référent(e) Vis ma vie *</h2>
            <main>
              <section>
                <InformationSection
                  label="NOM du ou de la référent(e)"
                  text={etablissement?.information_referent?.nom_referent ?? '-'}
                />
              <InformationSection label="Email" text={etablissement?.information_referent?.email_referent ?? '-'} />
              </section>
              <section>
                <InformationSection
                  label="Prénom du ou de la référent(e)"
                  text={etablissement?.information_referent?.prenom_referent ?? '-'}
                />
                <InformationSection label="Téléphone" text={formatPhoneNumber(etablissement?.information_referent?.phone_referent as string) ?? '-'} />
              </section>
            </main>
          </article>
        </section>
      </main>
    </article>
  );
}
