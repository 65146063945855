import { useState } from "react";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

import {
  minusCounterIcon,
  plusCounterIcon,
} from "../../../../assets/svgs/icons/icons.svg";
import {
  Disponibilite,
  Metier,
} from "../../../../schemas-and-types/etablissement/etablissemen.schema";
import styles from "./Metier.module.scss";
type JourBoxProps = {
  job_title: string;
  jour: Date;
  initialCount: number;
  metierIndex: number;
  metiers: Metier[];
  setMetiers: any;
};
export default function JourBox(props: JourBoxProps) {
  const { job_title, initialCount, jour, setMetiers } = props;

  const [accueilCount, setAccueilCount] = useState<number>(initialCount);

  function updateJoursAccueilsMinus() {
    // Updates the nombre_professionnel_disponible in the specific jour
    // when the ' - ' (minus) button is clicked
    // by decrementing the value of nombre_professionnel_disponible by one, for the specific jour.

    // accueilCount : for the UI
    // semaine.nombre_professionnel_disponible : for the actual metier object inside the metiers array

    // Here we make sure that the value of nombre_professionnel_disponible can't be negative
    if (accueilCount === 0) return;

    setAccueilCount((current) => current - 1);

    setMetiers((current: Metier[]) => [
      ...current.map((metier: Metier) => {
        if (metier.job_title === job_title) {
          return {
            job_title: metier.job_title,
            title: metier.title,
            isDisponible: metier.isDisponible,
            premiere_semaine: metier.premiere_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount - 1,
                  };
                } else return disponibilite;
              }
            ),
            deuxieme_semaine: metier.deuxieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount - 1,
                  };
                } else return disponibilite;
              }
            ),
            troisieme_semaine: metier.troisieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount - 1,
                  };
                } else return disponibilite;
              }
            ),
            quatrieme_semaine: metier.quatrieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount - 1,
                  };
                } else return disponibilite;
              }
            ),
          };
        } else return metier;
      }),
    ]);
  }

  function updateJoursAccueilsPlus() {
    // Updates the nombre_professionnel_disponible in the specific jour
    // when the ' + ' (plus) button is clicked
    // by incrementing the value of nombre_professionnel_disponible by one, for the specific jour.

    // accueilCount : for the UI
    // semaine.nombre_professionnel_disponible : for the actual metier object inside the metiers array
    setAccueilCount((current) => current + 1);

    setMetiers((current: Metier[]) => [
      ...current.map((metier: Metier) => {
        if (metier.job_title === job_title) {
          return {
            job_title: metier.job_title,
            title: metier.title,
            isDisponible: metier.isDisponible,
            premiere_semaine: metier.premiere_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount + 1,
                  };
                } else return disponibilite;
              }
            ),
            deuxieme_semaine: metier.deuxieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount + 1,
                  };
                } else return disponibilite;
              }
            ),
            troisieme_semaine: metier.troisieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount + 1,
                  };
                } else return disponibilite;
              }
            ),
            quatrieme_semaine: metier.quatrieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: accueilCount + 1,
                  };
                } else return disponibilite;
              }
            ),
          };
        } else return metier;
      }),
    ]);
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    // This executes when the user changes the value of
    // 'nombre_professionnel_disponible' using the keyboard
    if (event.target.value.charAt(0) === "0") {
      event.target.value = event.target.value.substring(1); // remove the first character
    }
    if (Number(event.target.value) === 0) {
      event.target.value = "";
    }
    if (Number(event.target.value) > 99) {
      event.target.value = "";

      return;
    }

    setMetiers((current: Metier[]) => [
      ...current.map((metier: Metier) => {
        if (metier.job_title === job_title) {
          return {
            job_title: metier.job_title,
            title: metier.title,
            isDisponible: metier.isDisponible,
            premiere_semaine: metier.premiere_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: Math.ceil(
                      Number(event.target.value)
                    ),
                  };
                } else return disponibilite;
              }
            ),
            deuxieme_semaine: metier.deuxieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: Math.ceil(
                      Number(event.target.value)
                    ),
                  };
                } else return disponibilite;
              }
            ),
            troisieme_semaine: metier.troisieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: Math.ceil(
                      Number(event.target.value)
                    ),
                  };
                } else return disponibilite;
              }
            ),
            quatrieme_semaine: metier.quatrieme_semaine.map(
              (disponibilite: Disponibilite) => {
                if (new Date(disponibilite.date).getTime() === jour.getTime()) {
                  return {
                    date: new Date(disponibilite.date),
                    nombre_professionnel_disponible: Math.ceil(
                      Number(event.target.value)
                    ),
                  };
                } else return disponibilite;
              }
            ),
          };
        } else return metier;
      }),
    ]);
    setAccueilCount(Number(event.target.value));
  }


  return (
    <article className={styles.metier__jourBox}>
      <section className={styles.metier__jourBox__date}>
        <label>
          {format(jour, "EEEE d MMMM", { locale: fr }).replace(/^./, (str) =>
            str.toUpperCase()
          )}
        </label>
      </section>
      <label
        className={styles.metier__jourBox__label}
        htmlFor={`possibiliteAccueilCount_${job_title}_${jour}`}
      >
        Possibilité(s) d'accueil(s)
      </label>
      <section className={styles.metier__jourBox__counter}>
        <button type="button" onClick={updateJoursAccueilsMinus}>
          {minusCounterIcon}
        </button>
        <input
          id={`possibiliteAccueilCount_${job_title}_${jour}`}
          type="number"
          min={0}
          max={99}
          value={accueilCount}
          onChange={handleOnChange}
          pattern="\d*"
          placeholder={String(accueilCount)}
          onFocus={(event) => {
            event.target.value = "";
          }}
          onBlur={(event) => {
            if (event.target.value === "") {
              event.target.value = String(accueilCount);
            }
          }}

          onWheel={(e) => {
            const target = e.target as HTMLElement;
            if (target) {
              target.blur();
            }
          }}

        ></input>
        <button type="button" onClick={updateJoursAccueilsPlus}>
          {plusCounterIcon}
        </button>
      </section>
    </article>
  );
}
