import styles from "./VMVDescription.module.scss";
function VMVDescription() {
  return (
    <article className={styles.vmvDescription}>
      <p>
        <strong>
          Du {process.env.REACT_APP_DATE_EVENT_LITERAL}, accueillez, avec vos équipes, au sein de votre
          établissement des collaborateurs et collaboratrices de nos sièges.
        </strong>
        <br></br>
        Cette initiative répond au souhait de tisser des liens, ou de les
        renforcer, entre nous tous, collaborateurs Clariane France.
      </p>
      <p>
        L'objectif est que les collaborateurs et collaboratrices des sièges
        participent concrètement au quotidien de vos équipes : préparation des
        repas, service à table, maintenance, nettoyage, repassage, accueil,
        animation...
      </p>
      <strong>
        Indiquez sur cette plateforme, les jours qui vous conviennent et les
        métiers possibles sur votre établissement.
        <br></br>
        Bon Vis ma vie !
      </strong>
    </article>
  );
}

export default VMVDescription;
