import { Etablissement, IEtablissement } from "../schemas-and-types/etablissement/etablissemen.schema";
import axiosClient from "../libs/axios";

async function getEtablissements(page: number) {
  return axiosClient.get(`/etablissements?page=${page}`);
}

async function getEtablissementBydId(id: string) {
  return axiosClient.get<Etablissement>(`/etablissements/${id}`);
}

async function addEtablissement(requestBody: IEtablissement) {
  return await axiosClient.post<Etablissement>("/etablissements", requestBody);
}

type PutParams = {
  id: string;
  requestBody: IEtablissement;
};
async function updateEtablissement(putParams: PutParams) {
  const { id, requestBody } = putParams;
  return axiosClient.put<Etablissement>(`/etablissements/${id}`, requestBody);
}

async function deleteEtablissement(id:string){
  return axiosClient.delete(`/etablissements/${id}`)
}
async function cancelEtablissement(id: string) {
  return axiosClient.put(`/etablissements/cancel/${id}`);
}

const etablissementServices = {
  getEtablissements,
  getEtablissementBydId,
  addEtablissement,
  updateEtablissement,
  deleteEtablissement,
  cancelEtablissement,
};

export default etablissementServices;
