import { useState, useEffect, useRef } from "react";
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import mapsSearchServices from "../../../services/maps-search/maps-search.service";

import styles from "./MapsSearchSection.module.scss";
import { Localisation } from "../../../schemas-and-types/etablissement/etablissemen.schema";

type Props = {
  label: string;
  placeholder: string;
  inputId: string;
  name: any;
  register: UseFormRegister<any>;
  formErrors: DeepMap<any, FieldError>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  localisationOption: Localisation;
  setLocalisationOption: React.Dispatch<React.SetStateAction<Localisation>>;
  setRegisterValue: UseFormSetValue<any>;
};

type ILocalisation = Localisation & { id: string };
export default function MapsSearchSection(props: Props) {
  const {
    label,
    placeholder,
    inputId,
    name,
    register,
    formErrors,
    setError,
    clearErrors,
    localisationOption,
    setLocalisationOption,
  } = props;
  const [searchTerm, setSearchTerm] = useState(
    localisationOption.address || ""
  );
  const [options, setOptions] = useState<ILocalisation[]>([]);
  const errorName = formErrors?.[name];
  const [isLoading, setIsLoading] = useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchTerm && localisationOption.address !== "") {
      clearErrors(name);
    }
   
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await mapsSearchServices.getMapSearchResults(
          searchTerm
        );
        setOptions(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    if (searchTerm) {
      fetchData();
    } else {
      setOptions([]);
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setAreOptionsVisible(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [searchTerm, localisationOption.address, name, setError]);

  return (
    <section ref={wrapperRef} className={styles.mapsSearchSection}>
      <label className={styles.mapsSearchSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <article className={styles.mapsSearchSection__search}>
        <input
          className={styles.mapsSearchSection__search__input}
          type="search"
          id={inputId}
          value={searchTerm}
          placeholder={placeholder}
          autoComplete="off"
          {...register(name)}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setAreOptionsVisible(true);
            if (!searchTerm && localisationOption.address === "") {

              setError(name, {
                type: "required",
                message: "Ce champ est obligatoire",
              });
            } else {
              clearErrors(name);
            }
            if (localisationOption.address === "") {
              setError(name, {
                type: "required",
                message: "Ce champ est obligatoire",
              });
            } else {
              clearErrors(name);
            }
          }}
          has-error={errorName ? "yes" : "no"} // check the style sheet
        />

        {areOptionsVisible && (
          <section className={styles.mapsSearchSection__search__options}>
            {isLoading ? (
              <option value={""}>Chargement...</option>
            ) : (
              <>
                <option value={placeholder} disabled hidden>
                  {placeholder}
                </option>
                {Array.isArray(options) && options.length === 0 ? (
                  <option value={placeholder}>Aucune addresse trouvée</option>
                ) : (
                  options.map((option: ILocalisation) => (
                    <option
                      onClick={(e) => {
                        setSearchTerm(e.currentTarget.value);
                        setLocalisationOption({
                          address: option.address,
                          region: option.region,
                          countrySecondarySubdivision:
                            option.countrySecondarySubdivision,
                          latitude: option.latitude,
                          longitude: option.longitude,
                        });
                        setOptions([]);
                        setAreOptionsVisible(false);
                      }}
                      key={option.id}
                      value={option.address}
                    >
                      {option.address}
                    </option>
                  ))
                )}
              </>
            )}
          </section>
        )}
      </article>
      <label className={styles.mapsSearchSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message}
      </label>
    </section>
  );
}
