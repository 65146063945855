import { UseFormSetError } from "react-hook-form";
import {
  EtablissementValidationType,
  IEtablissement, Localisation, Metier
} from "../../../schemas-and-types/etablissement/etablissemen.schema";

export function formatEtablissementObject(
  data: EtablissementValidationType,
  localisation: Localisation,
  oid: string,
  metiers: Metier[]
): IEtablissement {
  return {
    userOID: oid,
    name: data.name,
    type: data.type,
    information_access: data.information_access,

    information_regional: {
      region: data.region,
      nom_dr: data.nom_dr,
      prenom_dr: data.prenom_dr,
    },
    information_DE: {
      nom_de: data.nom_de,
      prenom_de: data.prenom_de,
      email_de: data.email_de,
      phone_de: data.phone_de,
    },
    information_referent: {
      nom_referent: data.nom_referent,
      prenom_referent: data.prenom_referent,
      email_referent: data.email_referent,
      phone_referent: data.phone_referent,
    },
    localisation: localisation,
    metiers: metiers.map((metier: Metier) => {
      // in order not avoid sending null instead of a indisponible metier
      if (metier.isDisponible === false)
        return {
          title: metier.title,
          job_title: metier.job_title,
          premiere_semaine: null,
          deuxieme_semaine: null,
          troisieme_semaine: null,
          quatrieme_semaine: null,
        };
      return {
        title: metier.title,
        job_title: metier.job_title,
        premiere_semaine: metier.premiere_semaine,
        deuxieme_semaine: metier.deuxieme_semaine,
        troisieme_semaine: metier.troisieme_semaine,
        quatrieme_semaine: metier.quatrieme_semaine,
      };
    }),
  };
}

export function executeAddressNotValideSideEffect(
  setError: UseFormSetError<any> // TODO : Remove any
) {
  setError("address", {
    type: "required",
    message: "Ce champ est obligatoire",
  });
  const anchor = document.getElementById("inscriptionEtab_infoEtab_adresse");
  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth" });
  }

}
