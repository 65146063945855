import { useQuery } from "@tanstack/react-query";
import regionsServices from "../../../services/dropdowns/regions.services";

export function useGetRegions() {
  return useQuery({
    queryKey: ["dropDowns-regions"],
    queryFn: regionsServices.getRegions,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });
}
